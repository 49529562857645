const { default: styled } = require("styled-components");

export const SectionTitle = styled.h1`
  font-size: 5rem;
  font-weight: bold;
  color: #494395;
  text-transform: initial;
  padding: 0;
  margin: 0;
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 3rem;
  }
  @media screen and (max-width: 670px) {
    text-align: center;
    font-size: 20px;
  }
`;
