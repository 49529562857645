import { useQuery } from "react-query";
import axios from "axios";
import { BASE_API_URL } from "../utils/app-constants";
import { SELECTED_BRANCH_KEY } from "../components/modal/branchModal";

export const useProduce = id => {
  const savedBranch = localStorage.getItem(SELECTED_BRANCH_KEY);
  const branch = savedBranch ? JSON.parse(savedBranch) : null;

  return useQuery(
    ["products", id, branch?.id],
    async () => {
      let url = `${BASE_API_URL}public/product`;

      // Build query parameters based on available data
      const params = new URLSearchParams();

      if (id) {
        params.append("product_type_id", id);
      }

      if (branch?.id) {
        params.append("warehouse_id", branch.id);
      }

      // Only add the query string if we have parameters
      if (params.toString()) {
        url = `${url}?${params.toString()}`;
      }

      const { data } = await axios.get(url);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {
      // Add query key dependencies to ensure proper cache invalidation
      enabled: true,
      staleTime: 5 * 60 * 1000 // 5 minutes
    }
  );
};

export const useProductTypes = () => {
  return useQuery(
    "product-types",
    async () => {
      const { data } = await axios.get(`${BASE_API_URL}public/producttype`);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {}
  );
};

export const useWarehouses = () => {
  return useQuery(
    "warehouses",
    async () => {
      const { data } = await axios.get(`${BASE_API_URL}public/warehouse`);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {}
  );
};

export const useCoupon = code => {
  return useQuery(
    "coupon",
    async () => {
      const { data } = await axios.get(
        `${BASE_API_URL}public/discount?code=${code}`
      );
      const { returned_resultset } = data;

      return returned_resultset[0];
    },
    {
      enabled: !!code
    }
  );
};
