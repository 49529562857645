import React, { useEffect, useState } from "react";
import "./App.css";
import { Homepage } from "./containers/homepage";
import { Switch, Route, useParams } from "react-router-dom";
import PrivacyPolicy from "./containers/privacyPolicy";
import TermsAndConditions from "./containers/TermsAndConditions";
import Products from "./containers/products/Products";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CartProvider } from "./subjects/CartContext";
import Checkout from "./containers/products/Checkout";
import { SELECTED_BRANCH_KEY } from "./components/modal/branchModal";
import axios from "axios"; // Assuming you're using axios for API calls
import { BASE_API_URL } from "./utils/app-constants";

const queryClient = new QueryClient();
function App(props) {
  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const pathname = window.location.pathname;

  // Fetch branches from the API
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axios.get(`${BASE_API_URL}public/warehouse`); // Replace with your API endpoint
        setBranches(response.data?.returned_resultset);
      } catch (error) {
        console.error("Error fetching branches:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBranches();
  }, []);

  // Set the selected branch based on the URL or localStorage
  useEffect(() => {
    if (!isLoading) {
      // Check if there's a branch in the URL
      if (
        pathname &&
        !pathname.includes("/privacy-policy") &&
        !pathname.includes("/terms-and-conditions")
      ) {
        const matchedBranch = branches.find(b =>
          pathname?.includes(b.name?.trim())
        );

        if (matchedBranch) {
          localStorage.setItem(
            SELECTED_BRANCH_KEY,
            JSON.stringify(matchedBranch)
          );
        } else {
          console.warn(
            "No branch found in the API matching the URL branch name."
          );
        }
      } else {
        // Fallback to localStorage if no branch in URL
        try {
          const savedBranch = localStorage.getItem(SELECTED_BRANCH_KEY);
          if (savedBranch) {
            // setSelectedBranch(JSON.parse(savedBranch));
          }
        } catch (error) {
          console.error("Error reading from localStorage:", error);
        }
      }
    }
  }, [pathname, branches, isLoading]);
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <CartProvider>
          <Switch>
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path={`/:branch/products`} component={Products} />
            <Route exact path="/" component={Homepage} />
          </Switch>
        </CartProvider>
      </QueryClientProvider>
      <ToastContainer position="top-center" />
    </>
  );
}

export default App;
