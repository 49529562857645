import React from "react";
import styled, { css } from "styled-components";

import MarkitiLogo from "../../assets/logo/new_logo2.png";
import { theme } from "../../theme";

const LogoContainer = styled.div`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
  align-items: center;
  margin-top: 2rem;
  @media screen and (max-width: 670px) {
    margin-top: 0.5rem;
  }
`;

const LogoImg = styled.img`
  width: 200px;
  height: 70px;

  @media screen and (max-width: 670px) {
    width: 100px;
    height: 40px;
  }

  ${({ inline }) =>
    inline &&
    css`
      width: 24px;
      height: 24px;
      margin-right: 6px;
    `};

  ${({ small }) =>
    small &&
    css`
      width: 8em;
      height: 8em;
    `};
`;

export function Logo(props) {
  const { inline, small } = props;

  return <LogoContainer>{<LogoImg src={MarkitiLogo} />}</LogoContainer>;
}
