import React, { useEffect, useState } from "react";
import { FaMinus, FaPlus, FaShoppingCart } from "react-icons/fa";
import styled from "styled-components";
import { useCart } from "../../subjects/CartContext";
import { MdProductionQuantityLimits } from "react-icons/md";
import ReactModal from "react-modal";
import { useMutation } from "react-query";
import axios from "axios";
import { BASE_API_URL } from "../../utils/app-constants";

const CardWrapper = styled.div`
  width: 320px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  overflow-x: hidden;
  &:hover {
    overflow: auto;
    img {
      opacity: 0.3;
    }
    .middle {
      opacity: 1;
    }
  }
`;

const DiscountBadge = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: #fee2e2;
  color: red;
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 0.8rem;
`;

const WishlistButton = styled.button`
  position: absolute;
  top: 16px;
  left: 16px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 8px;
  transition: background-color 0.3s;

  &:hover {
    background-color: white;
  }
`;

const ProductImage = styled.img`
  width: 90%;
  height: 50%;
  object-fit: contain;
  transition: transform 0.3s;
  margin: 1rem;
  border-radius: 12px;
  over-flow: hidden;
`;

const ProductDetails = styled.div`
  padding: 16px;
`;

const ProductName = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 12px;
  text-transform: capitalize;
`;

const QuantitySelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const QuantityControl = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 4px 8px;
`;

const QuantityButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #64748b;
`;
const Middle = styled.div`
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  color: #494395;
  font-weight: bold;
`;

const QuantityDisplay = styled.span`
  min-width: 40px;
  text-align: center;
`;

const PriceSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const DiscountedPrice = styled.span`
  color: #0f172a;
  font-weight: bold;
  font-size: 1rem;
  margin-right: 12px;
`;

const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: #999;
`;

const AddToCartButton = styled.button`
  background-color: #494395;
  color: white;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2ab4aa;
  }
`;

const SoldOutCard = styled.button`
  text-align: center;
  color: #fff;
  font-weight: bold;
  background-color: #494395;
  border-radius: 5px;
  border: none;
  margin-top: 1rem;
  padding: 8px 8px;
  width: 100%;
`;
const Container = styled.div`
  position: relative;
  z-index: 999,
  width: 50%;
  &:hover: {
    img {
      opacity: 0.2;
    }

     {
      opacity: 1;
    }
  }
`;

export const DiscountProductCard = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(1);
  const {
    title,
    imgUrl,
    backgroundColor,
    small,
    price,
    product,
    itemInCart,
    description,
    qty_available,
    discounted_price,
    discount_amount
  } = props;
  const { addItemToCart } = useCart();
  const saveReport = async () => {
    return axios.post(`${BASE_API_URL}public/product-notification`, {
      product_id: product.id
    });
  };
  const { mutate } = useMutation(saveReport);

  useEffect(() => {
    if (itemInCart) {
      setValue(itemInCart.qty);
    }
  }, [itemInCart]);

  const handleIncrement = index => {
    setValue(prev => prev + 1);
  };

  const handleDecrement = index => {
    if (value > 0) {
      setValue(prev => prev - 1);
    }
  };

  return (
    <CardWrapper>
      <DiscountBadge>Ksh. {discount_amount?.toFixed(0)} OFF</DiscountBadge>
      <ProductImage src={imgUrl} alt={product.name} />
      <Middle className="middle">{description ? description : title}</Middle>
      <ProductDetails>
        <ProductName>{title}</ProductName>

        <QuantitySelector>
          <span>Quantity</span>
          <QuantityControl>
            <QuantityButton onClick={handleDecrement}>
              <FaMinus size={16} />
            </QuantityButton>
            <QuantityDisplay>{value}</QuantityDisplay>
            <QuantityButton onClick={handleIncrement}>
              <FaPlus size={16} color="#64748b" />
            </QuantityButton>
          </QuantityControl>
        </QuantitySelector>

        <PriceSection>
          <PriceContainer>
            <DiscountedPrice>
              Ksh. {discounted_price.toFixed(2)}
            </DiscountedPrice>
            <OriginalPrice>Ksh. {price.toFixed(2)}</OriginalPrice>
          </PriceContainer>

          <AddToCartButton
            onClick={() => {
              if (qty_available <= 0) {
                setIsOpen(true);
                mutate();
                return;
              }
              addItemToCart(product, value);
            }}
          >
            <FaShoppingCart size={18} style={{ marginRight: 8 }} />
            Add to Cart
          </AddToCartButton>
        </PriceSection>
      </ProductDetails>
      <ReactModal
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.25)"
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            border: "none",
            width: "20rem",
            height: "fit-content"
          }
        }}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <h2 style={{ textAlign: "center" }}>Out of stock</h2>
          <MdProductionQuantityLimits size={42} color="#2ab4aa" />

          <p
            style={{ textAlign: "center", marginTop: "1rem", fontSize: "1rem" }}
          >
            We are sorry, this product is currently out of stock.
          </p>
        </div>
        <SoldOutCard onClick={() => setIsOpen(false)}>
          Continue Shopping
        </SoldOutCard>
      </ReactModal>
    </CardWrapper>
  );
};

export const RegularProductCard = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(1);
  const {
    title,
    imgUrl,
    backgroundColor,
    small,
    price,
    product,
    itemInCart,
    description,
    qty_available,
    discounted_price,
    discount_amount
  } = props;
  const { addItemToCart } = useCart();

  const saveReport = async () => {
    return axios.post(`${BASE_API_URL}public/product-notification`, {
      product_id: product.id
    });
  };
  const { mutate } = useMutation(saveReport);

  useEffect(() => {
    if (itemInCart) {
      setValue(itemInCart.qty);
    }
  }, [itemInCart]);

  const handleIncrement = index => {
    setValue(prev => prev + 1);
  };

  const handleDecrement = index => {
    if (value > 0) {
      setValue(prev => prev - 1);
    }
  };

  return (
    <CardWrapper>
      <ProductImage src={imgUrl} alt={title} />
      <Middle className="middle">{description ? description : title}</Middle>

      <ProductDetails>
        <ProductName>{title}</ProductName>

        <QuantitySelector>
          <span>Quantity</span>
          <QuantityControl>
            <QuantityButton onClick={handleDecrement}>
              <FaMinus size={16} />
            </QuantityButton>
            <QuantityDisplay>{value}</QuantityDisplay>
            <QuantityButton onClick={handleIncrement}>
              <FaPlus size={16} />
            </QuantityButton>
          </QuantityControl>
        </QuantitySelector>

        <PriceSection>
          <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
            Ksh. {price?.toFixed(2)}
          </span>

          <AddToCartButton
            onClick={() => {
              if (qty_available <= 0) {
                setIsOpen(true);
                mutate();
                return;
              }
              addItemToCart(product, value);
            }}
          >
            <FaShoppingCart size={18} style={{ marginRight: 8 }} />
            Add to Cart
          </AddToCartButton>
        </PriceSection>
      </ProductDetails>
      <ReactModal
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.25)"
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            border: "none",
            width: "20rem",
            height: "fit-content"
          }
        }}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <h2 style={{ textAlign: "center" }}>Out of stock</h2>
          <MdProductionQuantityLimits size={42} color="#2ab4aa" />

          <p
            style={{ textAlign: "center", marginTop: "1rem", fontSize: "1rem" }}
          >
            We are sorry, this product is currently out of stock.
          </p>
        </div>
        <SoldOutCard onClick={() => setIsOpen(false)}>
          Continue Shopping
        </SoldOutCard>
      </ReactModal>
    </CardWrapper>
  );
};
