import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { init, sendForm } from "emailjs-com";
import { useForm } from "react-hook-form";
import { Button } from "../button";
import { toast } from "react-toastify";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};
Modal.setAppElement("#root");

init("user_Q1UjiMTtd9FAM5Us9Zo7C");

const ModalComponent = ({ isOpen, setIsOpen }) => {
  const { register, handleSubmit, watch, formState, reset } = useForm();
  const { errors } = formState;

  const handleAddData = data => {
    sendForm("express_interest", "express_interest", "#interest-form").then(
      function (response) {
        toast.success("Thank you! Your request has been received");
        setIsOpen(false);
        reset();
      },
      function (error) {
        toast.error("Unable to send request! Please try again");
      }
    );
  };

  console.log(errors);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Header>
          <h4>Submit your details</h4>
        </Header>
        <Container id="interest-form" onSubmit={handleSubmit(handleAddData)}>
          <div>
            <label>Name</label>
            <Input
              name="user_name"
              {...register("user_name", { required: "Name is required" })}
            />
            {errors?.user_name && (
              <ErrorMessage>{errors.user_name.message}</ErrorMessage>
            )}
          </div>
          <div>
            <label>Business Name</label>
            <Input
              name="business_name"
              {...register("business_name", {
                required: "Business name is required"
              })}
            />
            {errors?.business_name && (
              <ErrorMessage>{errors.business_name.message}</ErrorMessage>
            )}
          </div>
          <div>
            <label>Location</label>
            <Input
              name="location"
              {...register("location", { required: "Please enter location" })}
            />
            {errors?.location && (
              <ErrorMessage>{errors.location.message}</ErrorMessage>
            )}
          </div>
          <div>
            <label>Email</label>
            <Input
              name="user_email"
              {...register("user_email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Enter a valid e-mail address"
                }
              })}
            />
            {errors?.location && (
              <ErrorMessage>{errors.user_email.message}</ErrorMessage>
            )}
          </div>
          <div>
            <label>Phone</label>
            <Input
              name="contact_number"
              {...register("contact_number", {
                required: "Phone number is required",
                pattern: {
                  value: /^(\+254|254|0)(7|1)\d{8}$/,
                  message: "Enter a valid phone_number"
                }
              })}
            />
            {errors?.contact_number && (
              <ErrorMessage>{errors.contact_number.message}</ErrorMessage>
            )}
          </div>
          <div>
            <label>Are you a chicken, fish trader?</label>
            <span>
              <input
                type="radio"
                name="trader"
                value="Yes"
                {...register("trader", { required: "Please select an option" })}
              />
              <label>yes</label>
            </span>
            <span>
              <input
                type="radio"
                name="trader"
                value="No"
                {...register("trader", { required: "Please select an option" })}
              />
              <label>No</label>
            </span>
            {errors?.trader && (
              <ErrorMessage>{errors.trader.message}</ErrorMessage>
            )}
          </div>
          <Button type="submit">Submit</Button>
          <CancelButton onClick={() => setIsOpen(false)}> Cancel</CancelButton>
        </Container>
      </Modal>
    </div>
  );
};

export default ModalComponent;

const Container = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  Button {
    width: 70%;
    margin: auto;
  }
  label {
    margin-right: 1em;
    margin-bottom: 0.5em;
  }

  @media screen and (max-width: 670px) {
    display: flex;
    flex-direction: column;
    Button {
      width: 70%;
      margin: auto;
      margin-bottom: 1em;
    }
  }
`;

const Header = styled.div`
  display: flex;
`;

const Input = styled.input`
  padding: 1em 1em;
  border-radius: 4px;
  width: 20rem;
  appearance: none;
  border: none;
  border: 1px solid black;
`;

const CancelButton = styled(Button)`
  background-color: #fff;
  color: black;
  border: 1px solid #26a4a2;
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 0;
  padding: 0;
  margin-bottom: 0.2em;
`;
