import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";

const CardContainer = styled.div`
  width: ${props => (props.small ? "18rem" : "26rem")};
  height: 28rem;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "rgba(73, 67, 149)"};
  position: relative;
  cursor: pointer;
  @media screen and (max-width: 1200px) {
    width: ${props => (props.small ? "20rem" : "23rem")};
    height: 28rem;
  }
  @media screen and (max-width: 768px) {
    width: 20rem
    height: 22rem;
  }
  @media screen and (max-width: 480px) {
    width: ${props => (props.small ? "18rem" : "18rem")};
    height: 28rem;
    margin-top: 1rem;
  }
`;

const ReviewText = styled.p`
  font-size: 17px;
  color: white;
  font-weight: normal;
  margin-block-start: 0;
  margin-block-end: 0;
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 1em;
  text-align: left;
  span {
    color: white;
    font-style: normal;
    font-weight: bold;
  }
`;

const UserImg = styled.img`
  width: 100%;
  height: 60%;
  transition: 0.5s ease-in-out;
  @media screen and (max-width: 768px) {
    height: 70%;
  }
  @media screen and (max-width: 480px) {
    height: 60%;
  }
`;

const Username = styled.h2`
  font-size: 25px;
  padding: 0;
  margin: 0;
  color: #fff;
`;

export function ReviewCard(props) {
  const { description, title, imgUrl, backgroundColor, small, details } = props;
  const history = useHistory();
  return (
    <CardContainer
      onClick={() => history.push("/products")}
      small={small}
      backgroundColor={backgroundColor}
    >
      <UserImg src={imgUrl} />
      <UserDetails>
        <Username>{title}</Username>
        <ReviewText>{description}</ReviewText>
      </UserDetails>
    </CardContainer>
  );
}
