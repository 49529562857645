import React from "react";
import styled, { css } from "styled-components";
import { ProductCard } from "../../components/productCard";
import { useProduce, useProductTypes } from "../../hooks/useProducts";
import {
  FaCartPlus,
  FaLocationArrow,
  FaShoppingBasket,
  FaTrash,
  FaMapMarkerAlt
} from "react-icons/fa";
import Logo from "../../assets/logo/new_logo_colored2.png";
import { slide as Menu } from "react-burger-menu";
import { useState } from "react";
import { Button } from "../../components/button";
import { useCart } from "../../subjects/CartContext";
import { thousands_separators } from "../../utils/formatCurrency";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useForm } from "react-hook-form";
import { getCurrentWarehouse } from "../../utils/getCurrentWarehouse";
import {
  BranchSelectionModal,
  SELECTED_BRANCH_KEY
} from "../../components/modal/branchModal";
import { EmptyStateComponent } from "../../components/modal/emptyState";
import {
  DiscountProductCard,
  RegularProductCard
} from "../../components/productCard/cards";

const getProductImage = name => {
  let image;
  switch (true) {
    case name.toLowerCase().includes("tilapia"):
      image =
        "https://api.beta.markiti.laheri.co.ke/upload/2dda0c86-f866-11ed-aba4-b526e0ab41d1-Tilapia-001_copy.jpg";
      break;
    case name.toLowerCase().includes("nile"):
    case name.toLowerCase().includes("cat"):
      image = require("../../assets/pictures/NP-steak.jpeg");
      break;
    case name.toLowerCase().includes("fillet"):
      image = require("../../assets/pictures/Fillet.jpeg");
      break;
    case name.toLowerCase().includes("omena"):
      image = require("../../assets/pictures/omena.jpeg");
      break;
    case name.toLowerCase().includes("chicken"):
      image = require("../../assets/pictures/chicken.jpeg");
      break;
    default:
      image = require("../../assets/pictures/chicken.jpeg");
      break;
  }
  return image;
};

var styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    right: "36px",
    top: "15px",
    zIndex: 1000
  },
  bmBurgerBars: {
    background: "#fff"
  }
};

const Products = () => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const { register, watch } = useForm();
  const filters = watch("product_type_id");
  const [isOpen, setIsOpen] = useState(false);
  const { data: products } = useProduce(selectedFilter);
  const { data: product_types } = useProductTypes();
  const { items, getTotalPrice, removeItem, getItemsCount } = useCart();

  const history = useHistory();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(() => {
    try {
      const savedBranch = localStorage.getItem(SELECTED_BRANCH_KEY);
      return savedBranch ? JSON.parse(savedBranch) : null;
    } catch (error) {
      console.error("Error reading from localStorage:", error);
      return null;
    }
  });
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  // Function to handle branch selection from the modal
  const handleSelectBranch = branch => {
    setSelectedBranch(branch);
  };

  return (
    <>
      <Container>
        <NavbarContainer>
          <LogoContainer onClick={() => window.location.replace("/")}>
            <LogoImg src={Logo} />
          </LogoContainer>
          <LocationContainer>
            <BranchContainer onClick={openModal}>
              <FaMapMarkerAlt size={24} color="#494395" />
              <div>
                <p>{selectedBranch?.name}</p>
                <strong>{selectedBranch?.code}</strong>
              </div>
            </BranchContainer>
            <BrandContainer onClick={() => setIsOpen(true)}>
              <FaCartPlus size={24} color="#494395" />
              <span>{getItemsCount()}</span>
            </BrandContainer>
          </LocationContainer>
        </NavbarContainer>
        <Header>
          <Title>{/* Our <span>Products</span> */}</Title>
        </Header>

        <ProductsContainer>
          <div>
            <strong>Filters</strong>
            <FiltersContainers>
              <Filter
                active={selectedFilter === ""}
                onClick={() => setSelectedFilter("")}
              >
                All
              </Filter>
              {product_types
                ?.filter(type => type.id !== 4)
                ?.map(type => (
                  <Tippy content={type?.description} key={type.id}>
                    <Filter
                      active={selectedFilter === type.id}
                      onClick={() => setSelectedFilter(type.id)}
                      key={type.id}
                    >
                      {type.name}
                    </Filter>
                  </Tippy>
                ))}
            </FiltersContainers>
          </div>

          {products?.length > 0 ? (
            <ProductList>
              {products
                ?.filter(
                  prod =>
                    prod.id !== "66d580f5-6b81-49a3-bde0-7305c7c407fe" &&
                    prod.id !== "2d1c4fe1-4be1-4f36-bfb5-159a42deb61e" &&
                    !prod?.name.includes("Byproducts")
                )
                ?.map(item => {
                  return item?.discount_amount ? (
                    <DiscountProductCard
                      product={item}
                      key={item.id}
                      description={item?.description}
                      title={item.name}
                      price={item.unit_value}
                      backgroundColor="white"
                      qty_available={item?.quantity_available_for_sale}
                      itemInCart={items?.find(
                        prod => prod?.product.id === item.id
                      )}
                      discounted_price={item?.discounted_price}
                      discount_amount={item?.discount_amount}
                      // imgUrl={getProductImage(item.display_name)}
                      imgUrl={
                        item?.product_images?.length
                          ? item?.product_images[0]?.url
                          : getProductImage(item.display_name)
                      }
                    />
                  ) : (
                    <RegularProductCard
                      product={item}
                      key={item.id}
                      description={item?.description}
                      title={item.name}
                      price={item.unit_value}
                      backgroundColor="white"
                      qty_available={item?.quantity_available_for_sale}
                      itemInCart={items?.find(
                        prod => prod?.product.id === item.id
                      )}
                      discounted_price={item?.discounted_price}
                      discount_amount={item?.discount_amount}
                      // imgUrl={getProductImage(item.display_name)}
                      imgUrl={
                        item?.product_images?.length
                          ? item?.product_images[0]?.url
                          : getProductImage(item.display_name)
                      }
                    />
                  );
                })}
            </ProductList>
          ) : (
            <EmptyStateComponent />
          )}
        </ProductsContainer>
      </Container>
      <BurgerMenu
        styles={styles}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        // noOverlay
        isOpen={isOpen}
        outerContainerId={"outer-container"}
        customBurgerIcon={false}
        right
      >
        <div>
          <MenuHeader>
            <>
              <FaShoppingBasket size={32} color="#747577" />
              <p style={{ color: "#787878" }}>{getItemsCount()} items</p>
            </>
          </MenuHeader>
          {items?.map(item => {
            return (
              <CartItem key={item.product?.id} className="menu-item">
                <div>
                  <p style={{ fontWeight: "bold" }}>
                    {" "}
                    {item.product?.display_name}
                  </p>
                  <p
                    style={{
                      color: "#8B8B8B",
                      textDecoration: item?.product?.discount_amount
                        ? "line-through"
                        : "none"
                    }}
                  >
                    {item?.qty} * {item.product?.unit_value}
                  </p>
                  {item?.product?.discount_amount ? (
                    <p>
                      {item?.qty} * {item.product?.discounted_price?.toFixed(0)}
                    </p>
                  ) : null}
                  {item?.product?.discounted_price ? (
                    <p>
                      Ksh.{" "}
                      {thousands_separators(
                        (item?.qty * item.product?.discounted_price).toFixed(0)
                      )}
                    </p>
                  ) : (
                    <p>
                      Ksh.{" "}
                      {thousands_separators(
                        item?.qty * item.product?.unit_value
                      )}
                    </p>
                  )}
                </div>
                <FaTrash
                  color="#8B8B8B"
                  onClick={() => removeItem(item.product?.id)}
                />
              </CartItem>
            );
          })}
          {items?.length && getTotalPrice() < 4000 ? (
            <CartItem className="menu-item">
              <div>
                <p style={{ fontWeight: "bold" }}>Delivery Fee</p>
                <p>Ksh. 400</p>
              </div>
            </CartItem>
          ) : null}

          <CheckOutActions>
            <Button onClick={() => history.push("/checkout")}>
              <Totals>
                Checkout Now{" "}
                <span>Total: Ksh. {thousands_separators(getTotalPrice())}</span>
              </Totals>
            </Button>
          </CheckOutActions>
        </div>
      </BurgerMenu>
      <BranchSelectionModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onSelectBranch={handleSelectBranch}
      />
    </>
  );
};

const Container = styled.div`
  display: grid;
  background-color: #f6f9fc;
  padding-bottom: 1rem;
`;

const BranchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0rem 1rem;
  cursor: pointer;
  div {
    p {
      font-weight: bold;
      color: #494395;
      padding: 0rem;
      margin: 0rem;
    }
    strong {
      font-weight: bold;
      padding: 0rem;
      margin: 0rem;
    }
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

const LocationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0rem 1rem;
  cursor: pointer;
`;

const FiltersContainers = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-evenly;
  align-items: center;
  margin: 1rem 0rem;
  column-gap: 1rem;
  row-gap: 1rem;
  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 11.5rem);
    column-gap: 1rem;
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(6, 11.5rem);
    column-gap: 1rem;
  }
`;

const Filter = styled.div`
  padding: 1rem;
  background-color: ${props => (props?.active ? "#494395" : "white")};
  color: ${props => (props?.active ? "white" : "#494395")};
  border-radius: 0.5rem;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
`;

const Title = styled.h3`
  font-size: 3.5rem;

  span {
    color: #494395;
  }
  @media screen and (max-width: 670px) {
    font-size: 1.5rem;
  }
`;

const ProductsContainer = styled.div`
  margin: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ProductList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 1rem;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
  }
`;
const NavbarContainer = styled.div`
  width: 100%;
  padding: 0.5rem 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  position: fixed;
  z-index: 10;
  color: black;
`;

const BrandContainer = styled.div`
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  border: 1px solid #494395;
  background-color: rgba(73, 67, 149, 0.1);
  padding: 0.5rem;
  span {
    background-color: red;
    color: white;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: -5px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
  align-items: center;
  @media screen and (max-width: 670px) {
    margin-top: 0.5rem;
  }
`;

const LogoImg = styled.img`
  width: 200px;
  height: 70px;

  @media screen and (max-width: 670px) {
    width: 100px;
    height: 40px;
  }

  ${({ inline }) =>
    inline &&
    css`
      width: 24px;
      height: 24px;
      margin-right: 6px;
    `};

  ${({ small }) =>
    small &&
    css`
      width: 8em;
      height: 8em;
    `};
`;

const MenuHeader = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid #ccc;
  p {
    margin-left: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CheckOutActions = styled.div`
  display: flex;
  padding: 1em;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  Button {
    border-radius: 0.5rem;
    width: 100%;
    padding: 0;
  }
`;

const Totals = styled.p`
  font-weight: bold;
  width: 100%;
  padding: 0;
`;

const BurgerMenu = styled(Menu)`
  width: 25% !important;
  height: 100%;
  position: absolute;
  top: 0;
  .bm-menu {
    z-index: 10;
    background: #fff;
    box-shadow: -1px 0px 5px 1px #aaaaaa;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .bm-cross {
    background: #747577;
  }
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0;
  }
  .bm-item-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 0;
  }
  .menu-item {
    color: black;
    font-weight: normal;
    padding: 0;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 0 0.75rem;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    &:hover {
      background: rgba(73, 67, 149, 0.1);
    }
  }
  @media screen and (max-width: 670px) {
    width: 100% !important;
    height: 100% !important;
    .menu-item {
      font-size: 18px;
    }
  }
`;

export default Products;
