import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// Styled components
const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 4rem 1rem;
  text-align: center;
`;

const IconContainer = styled.div`
  margin-bottom: 1.5rem;
  color: #d1d5db;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.5rem;
`;

const Description = styled.p`
  color: #6b7280;
  max-width: 28rem;
  margin: 0 auto 2rem auto;
  line-height: 1.5;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 640px) {
    flex-direction: row;
  }
`;

const PrimaryButton = styled.button`
  padding: 0.5rem 1.5rem;
  background-color: #2563eb;
  color: white;
  font-weight: 500;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #1d4ed8;
  }
`;

const SecondaryButton = styled.button`
  padding: 0.5rem 1.5rem;
  background-color: white;
  color: #4b5563;
  font-weight: 500;
  border-radius: 0.375rem;
  border: 1px solid #d1d5db;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f9fafb;
  }
`;

// SVG icon component
const CartIcon = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
  </svg>
);

// Main component
export const EmptyStateComponent = ({
  filteredCategory = "products",
  onClearFilters = () => {},
  onBrowseAll = () => {}
}) => {
  return (
    <EmptyStateContainer>
      <IconContainer>
        <CartIcon />
      </IconContainer>

      <Title>No Products found</Title>

      <Description>
        We couldn't find any products at the moment in your current branch. Try
        adjusting your branch to browse more products.
      </Description>

      <ButtonGroup>
        <SecondaryButton onClick={() => window.location.replace("/")}>
          Browse All Products
        </SecondaryButton>
      </ButtonGroup>
    </EmptyStateContainer>
  );
};
