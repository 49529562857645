import React from "react";
import styled, { css } from "styled-components";
import { FaCartPlus, FaShoppingBasket, FaMapMarkerAlt } from "react-icons/fa";
import Logo from "../../assets/logo/new_logo_colored2.png";
import { slide as Menu } from "react-burger-menu";
import { useState } from "react";
import { Button } from "../../components/button";
import { useCart } from "../../subjects/CartContext";
import { thousands_separators } from "../../utils/formatCurrency";
import {
  CheckBox,
  FormGroup,
  FormGroupCheckbox,
  Input,
  Label,
  Message,
  SelectInput,
  TextArea
} from "../../components/forms/Forms";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useEffect } from "react";
import { formatPhoneNumber } from "../../utils/formatPhonenumber";
import { Wizard, useWizard } from "react-use-wizard";
import { BASE_API_URL } from "../../utils/app-constants";
import { getCurrentWarehouse } from "../../utils/getCurrentWarehouse";
import { SELECTED_BRANCH_KEY } from "../../components/modal/branchModal";
import { useCoupon } from "../../hooks/useProducts";

//TODO  discount_code
//public/discount?code=discount_code

const UserDetails = ({ setOrder, setOrderNumber, items }) => {
  const [otpSent, setOtpSent] = useState(false);
  const [verificationComplete, setVerificationComplete] = useState(false);
  const { handleStep, previousStep, nextStep } = useWizard();
  const { setIsSelfCollection, coupon } = useCart();
  const [selectedBranch, setSelectedBranch] = useState(() => {
    try {
      const savedBranch = localStorage.getItem(SELECTED_BRANCH_KEY);
      return savedBranch ? JSON.parse(savedBranch) : null;
    } catch (error) {
      console.error("Error reading from localStorage:", error);
      return null;
    }
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm();

  const handlePlaceOrder = data => {
    return axios.post(`${BASE_API_URL}public/order`, data);
  };

  const { mutate, isLoading, isSuccess } = useMutation(handlePlaceOrder, {
    onSuccess: results => {
      const { returned_resultset } = results.data;
      setOrderNumber(returned_resultset[0]?.order_number);
      setOrder(returned_resultset[0]);
      toast.success(
        "Order placed succesfully, Kindly make payment using the instructions below"
      );
      nextStep();
    },
    onError: error => {
      toast.error(
        `${error?.response?.data?.message} Unable to complete your request please try again`
      );
    }
  });

  const handleVerifyPhoneNumber = data => {
    return axios.post(`${BASE_API_URL}auth/otp/generate`, data);
  };
  const handleVerifyCode = data => {
    return axios.post(`${BASE_API_URL}auth/otp/verify`, data);
  };

  const { mutate: verifyPhoneNumber } = useMutation(handleVerifyPhoneNumber, {
    onSuccess: results => {
      const { returned_resultset } = results.data;
      setOtpSent(true);
      toast.success("Otp sent to your phone number");
    },
    onError: error => {
      toast.error(
        `${error?.response?.data?.message} Unable to send otp please try again`
      );
    }
  });
  const { mutate: submitOtp } = useMutation(handleVerifyCode, {
    onSuccess: results => {
      const { returned_resultset } = results.data;
      setOtpSent(false);
      setVerificationComplete(true);
      toast.success("Phone number verified");
    },
    onError: error => {
      toast.error(
        `${error?.response?.data?.message} Unable to verify phone number please try again`
      );
    }
  });

  const onSubmit = data => {
    const cartItems = items?.map(item => {
      return {
        product_id: item.product.id,
        quantity: item.qty
      };
    });
    let clonedData = { ...data };
    delete clonedData.date;
    delete clonedData.is_company;
    delete clonedData.self_collection;
    if (cartItems.length) {
      const payload = {
        ...clonedData,
        phone_number: formatPhoneNumber(data?.phone_number),
        cart_items: cartItems,
        delivery_time: `${data?.date}, ${data?.delivery_time}`,
        warehouse_id: selectedBranch?.id
      };
      if (isSelfPickup) {
        payload.delivery_fee_status = "self-collect";
      }
      if (coupon) {
        payload.discount_code = coupon?.code;
      }
      mutate(payload);
    } else {
      toast.error("No items in basket");
    }
  };

  const today = new Date();

  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  const isCompany = watch("is_company");
  const phoneNumber = watch("phone_number");
  const isSelfPickup = watch("self_collection");

  const isSunday = dateString => {
    const selectedDate = new Date(dateString);
    return selectedDate.getDay() === 0; // 0 represents Sunday
  };

  useEffect(() => {
    setIsSelfCollection(isSelfPickup);
  }, [isSelfPickup, setIsSelfCollection]);

  return (
    <Form>
      <FormActions>
        <h3>Step 1</h3>
      </FormActions>
      <FormActions>
        <strong>Enter your details</strong>
      </FormActions>
      <FormGroup style={{ flexDirection: "row", display: "flex" }}>
        <FormGroupCheckbox style={{ marginRight: "1rem" }}>
          <Label>Business</Label>
          <CheckBox
            type="radio"
            {...register("is_company", {
              required: "Type is required"
            })}
            value={true}
            name="is_company"
          />
        </FormGroupCheckbox>
        <FormGroupCheckbox>
          <Label>Individual</Label>
          <CheckBox
            type="radio"
            {...register("is_company", {
              required: "Type is required"
            })}
            name="is_company"
            value={false}
            defaultChecked
          />
        </FormGroupCheckbox>
      </FormGroup>
      {isCompany && isCompany === "true" ? (
        <FormGroup>
          <Label>
            Business Name <span style={{ color: "red" }}>*</span>
          </Label>
          <Input
            name="names"
            {...register("names", {
              required: "Business name is required"
            })}
          />
          <Message>{errors?.names?.message}</Message>
        </FormGroup>
      ) : (
        <FormGroup>
          <Label>
            Full Name <span style={{ color: "red" }}>*</span>
          </Label>
          <Input
            name="first_name"
            {...register("first_name", {
              required: "Name is required"
            })}
          />
          <Message>{errors?.first_name?.message}</Message>
        </FormGroup>
      )}
      <FormGroup>
        <Label>
          Phone Number <span style={{ color: "red" }}>*</span>
        </Label>
        <Input
          placeholder="Enter your number as 07XX XXX XXX"
          name="phone_number"
          {...register("phone_number", {
            required: "Phone Number is required"
          })}
        />
        {otpSent && (
          <Input
            placeholder="Enter the code sent to your number"
            name="otp_code"
            {...register("otp_code", {
              required: "Phone Number is required"
            })}
          />
        )}
        {phoneNumber && !verificationComplete ? (
          <Button
            onClick={() =>
              otpSent
                ? submitOtp({
                    phone_number: formatPhoneNumber(phoneNumber),
                    otp: watch("otp_code")
                  })
                : verifyPhoneNumber({
                    phone_number: formatPhoneNumber(phoneNumber)
                  })
            }
            small
            type="button"
          >
            {otpSent ? "Submit Code" : "Verify Phone Number"}
          </Button>
        ) : (
          ""
        )}
        <Message>{errors?.phone_number?.message}</Message>
      </FormGroup>

      <FormGroup>
        <Label>
          Location <span style={{ color: "red" }}>*</span>
        </Label>
        <Input
          name="address"
          {...register("address", {
            required: "Please add a location"
          })}
        />
        <Message>{errors?.address?.message}</Message>
      </FormGroup>
      <FormGroup>
        <Label>
          Building <span style={{ color: "red" }}>*</span>
        </Label>
        <Input
          placeholder="Nearest landmark e.g school"
          name="building"
          {...register("building", {
            required: "Building name is required"
          })}
        />
        <Message>{errors?.building?.message}</Message>
      </FormGroup>

      <FormGroup>
        <Label>Notes</Label>
        <TextArea
          placeholder="Enter any instructions for your order"
          name="notes"
          {...register("notes")}
        />
      </FormGroup>
      <FormGroup>
        <p>Disclaimers:</p>
        <ul>
          <li>Please note that we only do next day delivery</li>
          <li>Delivery in Nairobi and its environs only</li>
          <li>We charge delivery of 400 KES</li>
          <li>Free delivery for orders above Ksh 4,000</li>
          <li>Please note you can use your name as the business name</li>
        </ul>
      </FormGroup>
      <FormGroupCheckbox>
        <Label>Self Collection</Label>
        <CheckBox
          type="checkbox"
          name="self_collection"
          {...register("self_collection")}
        />
      </FormGroupCheckbox>
      {isSelfPickup ? null : (
        <>
          <FormGroup>
            <Label>
              Delivery date <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type="date"
              placeholder="Nearest landmark e.g school"
              name="date"
              min={tomorrow.toISOString().split("T")[0]}
              {...register("date", {
                required: "Delivery name is required",
                validate: value =>
                  !isSunday(value) || "No deliveries on Sundays"
              })}
            />
            <Message>{errors?.date?.message}</Message>
          </FormGroup>
          <FormGroup>
            <Label>
              Preferred time of delivery <span style={{ color: "red" }}>*</span>
            </Label>
            <SelectInput
              placeholder="Nearest landmark e.g school"
              name="delivery_time"
              {...register("delivery_time")}
            >
              <option value="Late Morning">Late Morning</option>
              <option value="Afternoon">Afternoon</option>
              <option value="Evening">Evening</option>
            </SelectInput>
            <Message>{errors?.delivery_time?.message}</Message>
          </FormGroup>{" "}
        </>
      )}

      <FormGroupCheckbox>
        <Label>
          Allow promotional messages<span style={{ color: "red" }}>*</span>
        </Label>
        <CheckBox
          type="checkbox"
          name="notification_consent"
          {...register("notification_consent")}
        />
      </FormGroupCheckbox>

      <Message>{errors?.building?.message}</Message>
      <FormActions>
        <Button disabled={isLoading} onClick={handleSubmit(onSubmit)}>
          <Totals>
            {isLoading ? "Completing Order..." : "Complete Order"}
          </Totals>
        </Button>
      </FormActions>
    </Form>
  );
};

const Payment = ({ setItems, getTotalPrice, orderNumber, order }) => {
  const history = useHistory();
  const {
    register: stkRegister,
    handleSubmit: stkHandleSubmit,
    setValue
  } = useForm();

  const { coupon } = useCart();

  const phoneNumber = order?.agent?.contact_phonenumber;
  useEffect(() => {
    if (order?.id && phoneNumber) setValue("stk_phone_number", phoneNumber);
  }, [phoneNumber, order, setValue]);

  const handleStkPush = data => {
    return axios.post(`${BASE_API_URL}public/order/${order.id}/stk`, data);
  };

  const { mutate: stkMutation, isLoading } = useMutation(handleStkPush, {
    onSuccess: () => {
      toast.success("Order successful");
      setItems([]);
      history.push("/products");
    },
    onError: error => {
      toast.error(
        error?.response?.data?.message ??
          "Payment request failed. Kindly pay using the paybill number"
      );
    }
  });

  const handlePaylaterPush = data => {
    return axios.put(`${BASE_API_URL}public/order/${order.id}`, data);
  };

  const { mutate } = useMutation(handlePaylaterPush, {
    onSuccess: () => {
      toast.success("Order successful");
      setItems([]);
      history.push("/products");
    },
    onError: error => {
      toast.error(
        error?.response?.data?.message ??
          "Payment request failed. Kindly pay using the paybill number"
      );
    }
  });

  const stkSubmit = data => {
    const payload = {
      phonenumber: formatPhoneNumber(data.stk_phone_number),
      amount: coupon?.discount_amount
        ? getTotalPrice() - coupon.discount_amount
        : getTotalPrice()
    };
    stkMutation(payload);
  };

  return (
    <PaymentDetails>
      <FormActions>
        <h3>Step 2</h3>
      </FormActions>
      <FormActions>
        <strong>Complete Payment</strong>
      </FormActions>
      <p>Click on Pay to initiate the M-PESA payment</p>
      <p>Check your mobile for the M-PESA pin prompt</p>
      <p>Enter M-PESA pin to approve payment</p>
      <FormGroup>
        <Label>
          Please provide the phone number your would like to pay with (M-Pesa):
        </Label>
        <Input
          placeholder="Enter phone number for M-PESA payment"
          name="stk_phone_number"
          defaultValue={phoneNumber}
          {...stkRegister("stk_phone_number", {
            required: "Phone Number is required"
          })}
        />
      </FormGroup>
      <div style={{ display: "flex" }}>
        <NewButtonPrimary
          disabled={isLoading}
          onClick={stkHandleSubmit(stkSubmit)}
        >
          Pay Now
        </NewButtonPrimary>
        <NewButton
          style={{ marginLeft: "1rem" }}
          disabled={isLoading}
          onClick={() => {
            mutate({ payment_mode: "ondelivery" });
          }}
        >
          Pay on delivery
        </NewButton>
      </div>

      <FormActions>
        <strong>you can also pay directly to our M-PESA paybill number </strong>
      </FormActions>

      <p>Kindly make payment using the instructions below</p>
      <p>Select ‘Pay Bill’ from your M-PESA</p>
      <p>
        Enter the ‘Business Numbers: <span>4015545</span>
      </p>
      <p>
        Enter the ‘Account Number’: <span>{orderNumber}</span>{" "}
      </p>
      <p>
        Enter the amount:{" "}
        <span>
          {coupon?.discount_amount
            ? getTotalPrice() - coupon.discount_amount
            : getTotalPrice()}
        </span>{" "}
      </p>
      <p>Confirm that all the details are correct and press Ok</p>
      <Button
        disabled={isLoading}
        onClick={() => {
          toast.success("Order successful");
          setItems([]);
          history.push("/products");
        }}
      >
        <Totals>Confirm payment</Totals>
      </Button>
    </PaymentDetails>
  );
};

const Checkout = () => {
  const [code, setCode] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [order, setOrder] = useState();

  const { register, watch } = useForm();

  const couponCode = watch("code");

  const { data: coupon } = useCoupon(code);

  const {
    items,
    getTotalPrice,
    removeItem,
    getItemsCount,
    setItems,
    isSelfCollection,

    setCoupon
  } = useCart();
  const currentWarehouse = getCurrentWarehouse();

  useEffect(() => {
    setCoupon(coupon);
  }, [coupon, setCoupon]);

  return (
    <>
      <Container>
        <NavbarContainer>
          <LogoContainer onClick={() => null}>
            <LogoImg src={Logo} />
          </LogoContainer>
          <LocationContainer>
            <BranchContainer>
              <FaMapMarkerAlt size={24} color="#494395" />
              <div>
                <p>{currentWarehouse?.name}</p>
                <strong>{currentWarehouse?.code}</strong>
              </div>
            </BranchContainer>
            <BrandContainer onClick={() => setIsOpen(true)}>
              <FaCartPlus size={24} color="#494395" />
              <span>{getItemsCount()}</span>
            </BrandContainer>
          </LocationContainer>
        </NavbarContainer>
        <Header>
          <Title>
            <span>Checkout</span>
            <p style={{ fontSize: "1.5rem" }}>Please complete step 1 & 2</p>
          </Title>
        </Header>
        <ProductsContainer>
          <Wizard>
            <UserDetails
              setOrder={setOrder}
              setOrderNumber={setOrderNumber}
              items={items}
            />
            <Payment
              setItems={setItems}
              getTotalPrice={getTotalPrice}
              orderNumber={orderNumber}
              order={order}
            />
          </Wizard>

          <ProductList>
            <div>
              <MenuHeader>
                <>
                  <FaShoppingBasket size={32} color="#747577" />
                  <p style={{ color: "#787878" }}>{getItemsCount()} items</p>
                </>
              </MenuHeader>
              {items?.map(item => {
                return (
                  <CartItem
                    key={item?.product.display_name}
                    className="menu-item"
                  >
                    <div>
                      <p style={{ fontWeight: "bold" }}>
                        {" "}
                        {item.product?.display_name}
                      </p>
                      <p
                        style={{
                          color: "#8B8B8B",
                          textDecoration: item?.product?.discount_amount
                            ? "line-through"
                            : "none"
                        }}
                      >
                        {item?.qty} * {item.product?.unit_value}
                      </p>
                      {item?.product?.discount_amount ? (
                        <p>
                          {item?.qty} *{" "}
                          {item.product?.discounted_price?.toFixed(0)}
                        </p>
                      ) : null}
                      {item?.product?.discounted_price ? (
                        <p>
                          Ksh.{" "}
                          {thousands_separators(
                            (
                              item?.qty * item.product?.discounted_price
                            ).toFixed(2)
                          )}
                        </p>
                      ) : (
                        <p>
                          Ksh.{" "}
                          {thousands_separators(
                            item?.qty * item.product?.unit_value
                          )}
                        </p>
                      )}
                    </div>
                  </CartItem>
                );
              })}
              {!isSelfCollection ? (
                <div>
                  {items?.length && getTotalPrice() < 4000 ? (
                    <CartItem className="menu-item">
                      <div>
                        <p style={{ fontWeight: "bold" }}>Delivery Fee</p>
                        <p>Ksh. 400</p>
                      </div>
                    </CartItem>
                  ) : null}
                </div>
              ) : null}

              <div className="coupon-container">
                <input
                  style={{ padding: "3rem !important" }}
                  placeholder="Enter Coupon"
                  name="coupon"
                  {...register("code")}
                />
                <NewButton onClick={() => setCode(couponCode)}>Apply</NewButton>
              </div>

              {items?.length ? (
                <CheckOutActions>
                  {coupon ? (
                    <Totals>
                      <div>
                        <p>Items Total:</p>
                        <span>
                          Ksh. {thousands_separators(getTotalPrice())}
                        </span>
                      </div>
                      <div>
                        <p>Discount:</p>
                        <span style={{ color: "#14b8a6" }}>
                          Ksh. {coupon?.discount_amount}
                        </span>
                      </div>
                      <div>
                        <p>Order Total:</p>
                        <span>
                          Ksh.
                          {thousands_separators(
                            getTotalPrice() - coupon?.discount_amount
                          )}
                        </span>
                      </div>
                    </Totals>
                  ) : (
                    <Totals>
                      <span>
                        Order Total: Ksh.{" "}
                        {thousands_separators(getTotalPrice())}
                      </span>
                    </Totals>
                  )}
                </CheckOutActions>
              ) : null}
            </div>
          </ProductList>
        </ProductsContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: block;
  background-color: #f6f9fc;
  padding-bottom: 1rem;
  column-gap: 2rem;
`;

const Form = styled.form`
  background-color: white;
  display: flex;
  column-gap: 2rem;
  row-gap: 1rem;
  flex-direction: column;
  padding: 3rem;
  border-radius: 1rem;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  /* @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 1fr;
  }
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr;
  } */
`;

const FormActions = styled.div`
  grid-column: 1 / span 2;
  Button {
    border-radius: 0.5rem;
    /* width: 100%; */
    padding: 0 2rem;
    background-color: #fff;
    color: black;
    border: 1px solid #494395;
    &:hover {
      background-color: #494395;
      color: white;
    }
  }
  h3 {
    padding: 0;
    margin: 0;
  }
`;

const NewButton = styled.button`
  border-radius: 0.2rem !important;
  /* width: 100%; */
  padding: 0.4rem 2rem !important;
  background-color: #fff;
  color: black;
  border: 1px solid #494395;
  margin: 1rem 0rem;
  &:hover {
    background-color: #494395;
    color: white;
  }
`;

const NewButtonPrimary = styled(Button)`
  border-radius: 0.2rem !important;
  /* width: 100%; */
  padding: 0.4rem 2rem !important;
  background-color: #494395 !important;
  color: #fff !important;
  border: 1px solid #494395 !important;
  margin: 1rem 0rem;
  &:hover {
    border: 1px solid #2ab4aa !important;
    background-color: #2ab4aa !important;
    color: white;
  }
`;

const PaymentDetails = styled.div`
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: #fff;
  border-radius: 1rem;
  margin: 1rem 0;
  padding: 1rem;
  @media (min-width: 900px) {
    margin-top: 0;
  }
  @media (min-width: 600px) {
    margin-top: 0;
  }

  span {
    font-weight: bold;
  }
  Button {
    border-radius: 0.5rem;
    /* width: 100%; */
    padding: 0rem 2rem;
    background-color: #fff;
    color: black;
    border: 1px solid #494395;
    &:hover {
      background-color: #494395;
      color: white;
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 0 3.5rem;
  padding-top: 4.5rem;
  p {
    font-size: 1.5rem;
    font-weight: bold;
  }
  @media (min-width: 900px) {
    padding: 0 12rem;
    padding-top: 4.5rem;
  }
  @media (min-width: 600px) {
    padding: 0 12rem;
    padding-top: 4.5rem;
  }
`;

const Title = styled.h3`
  font-size: 2.5rem;
  padding: 0;
  margin: 1rem 0;
  span {
    color: #494395;
  }
  p {
    margin: 0.5rem 0;
  }
`;

const ProductsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 3rem;
  column-gap: 2rem;
  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    margin: 0 9rem;
  }
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    margin: 0 9rem;
  }
`;
const ProductList = styled.div`
  display: block;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin-top: 1rem;
  @media (min-width: 900px) {
    margin-top: 0;
  }
  @media (min-width: 600px) {
    margin-top: 0;
  }
`;
const NavbarContainer = styled.div`
  width: 100%;
  padding: 0.5rem 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  position: fixed;
  z-index: 10;
  color: black;
`;

const BrandContainer = styled.div`
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  border: 1px solid #494395;
  background-color: rgba(73, 67, 149, 0.1);
  padding: 0.5rem;
  span {
    background-color: red;
    color: white;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: -5px;
  }
`;
const BranchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0rem 1rem;
  cursor: pointer;
  div {
    p {
      font-weight: bold;
      color: #494395;
      padding: 0rem;
      margin: 0rem;
    }
    strong {
      font-weight: bold;
      padding: 0rem;
      margin: 0rem;
    }
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

const LocationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0rem 1rem;
  cursor: pointer;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
  align-items: center;
  @media screen and (max-width: 670px) {
    margin-top: 0.5rem;
  }
`;

const LogoImg = styled.img`
  width: 200px;
  height: 70px;

  @media screen and (max-width: 670px) {
    width: 100px;
    height: 40px;
  }

  ${({ inline }) =>
    inline &&
    css`
      width: 24px;
      height: 24px;
      margin-right: 6px;
    `};

  ${({ small }) =>
    small &&
    css`
      width: 8em;
      height: 8em;
    `};
`;

const MenuHeader = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid #ccc;
  p {
    margin-left: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid #ccc;
`;

const CheckOutActions = styled.div`
  display: flex;
  padding: 1em;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  Button {
    border-radius: 0.5rem;
    width: 100%;
    padding: 0;
  }
`;

const Totals = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-weight: bold;
  width: 100%;
  padding: 0;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export default Checkout;
