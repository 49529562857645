import React, { createContext, useContext, useState } from "react";

import { toast } from "react-toastify";

export const CartContext = createContext({});

export function CartProvider(props) {
  const [items, setItems] = useState([]);
  const [isSelfCollection, setIsSelfCollection] = useState(false);
  const [coupon, setCoupon] = useState(null);

  console.log(coupon, "cp");

  function addItemToCart(product, qty) {
    setItems(prevItems => {
      const item = prevItems.find(item => item.product.id == product.id);
      if (!item) {
        return [
          ...prevItems,
          {
            qty: qty ? qty : 1,
            product,
            discountedTotal: product?.discounted_price?.toFixed(0) * qty,
            totalPrice: product.unit_value * qty
          }
        ];
      } else {
        return prevItems.map(item => {
          if (item.product.id == product.id) {
            item.qty = qty;
            item.totalPrice = product.unit_value * qty;
            item.discountedTotal = product?.discounted_price * qty;
          }
          return item;
        });
      }
    });
    toast.success("Item added to basket");
  }

  function removeItem(id) {
    return setItems(() => items?.filter(item => item?.product.id !== id));
  }

  function updateCartItem(updateditems) {
    setItems(() => updateditems);
  }
  function getItemsCount() {
    return items.length;
  }

  function getTotalPrice() {
    const couponAmount = coupon?.discount_amount ?? 0;
    const totalPrice = items.reduce(
      (sum, item) => sum + (item?.discountedTotal || item.totalPrice),
      0
    );
    return isSelfCollection
      ? totalPrice
      : totalPrice < 4000
      ? totalPrice + 400
      : totalPrice;
  }

  return (
    <CartContext.Provider
      value={{
        items,
        setItems,
        getItemsCount,
        addItemToCart,
        getTotalPrice,
        updateCartItem,
        removeItem,
        setIsSelfCollection,
        isSelfCollection,
        coupon,
        setCoupon
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}
